<template>
  <div
    ref="draggableContainer"
    class="absolute top-0 left-0 z-50 h-full min-h-0 p-4 overflow-hidden overflow-y-auto rounded-lg w-80" :style="style"
  >
    <div class="flex items-center h-6 bg-gray-700 select-none cursor-grab hover:bg-gray-600"  @mousedown="onDragMouseDown">
      <div class="mx-1">
        <div class="w-2 h-2 bg-red-500 rounded-full hover:bg-red-600 hover:cursor-pointer" @click.prevent="$emit('close')" />
      </div>
      <div class="mx-1"></div>
    </div>
    <div
      class="relative flex-1 overflow-y-auto text-white transition-all bg-gray-800 rounded-md sidebar-content"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sizeY: 'auto',
      sizeX: 400,
      top: 20,
      right: 20,
      positions: {
        clientX: 20,
        clientY: 20,
        movementX: 0,
        movementY: 0
      }
    }
  },

  computed: {
    style() {
      return {
        width: this.sizeX + 'px',
        right: this.right + 'px',
        top: this.top + 'px'
      }
    }
  },

  methods: {
    onDragMouseDown(event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },

    elementDrag(event) {
        event.preventDefault()
        this.positions.movementX = this.positions.clientX - event.clientX
        this.positions.movementY = this.positions.clientY - event.clientY
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        // set the element's new position:
        this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
        this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },

    closeDragElement () {
        document.onmouseup = null
        document.onmousemove = null
    }
  }
}
</script>
